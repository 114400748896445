import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { VARIABLES } from '../../Constant';

const Login = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false)
  console.log(submitLoading)

  const [showPassword, setShowPassword] = useState(false)

  const showPasswordHandler = () => {
    setShowPassword(!showPassword)
  }

  // Onsubmit handler for Login 
  const onSubmit = (data) => {
    const url = VARIABLES.url + '/api/login';

    let headers = { "Content-Type": "application/json" }

    let reqOptions = {
      url: url,
      method: "POST",
      headers: headers,
      mode: 'no-cors',
      data: data,
    }

    setSubmitLoading(true)
    axios.request(reqOptions).then((response) => {
      const userData = response.data;
      const loginStatus = response.status;
      if (loginStatus === 200) {
        props.loginHandler(userData)
        setSubmitLoading(false)
      } else if (loginStatus === 210) {
        Swal.fire({
          title: userData.status,
          text: "Please check password",
          icon: "error"
        });
        setSubmitLoading(false)
      } else if (loginStatus === 211) {
        Swal.fire({
          title: userData.status,
          text: "Please check username",
          icon: "error"
        });
        setSubmitLoading(false)
      }
    }).catch((error) => {
      console.log(error.response);
      setSubmitLoading(false)
    })
  };

  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap nk-wrap-nosidebar">
            {/* <!-- content @s --> */}
            <div className="nk-content ">
              <div className="nk-split nk-split-page nk-split-lg">
                <div className="nk-split-content bg-color-login nk-block-area nk-block-area-column nk-auth-container">
                  <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                    <a href="javascript:void(0)" className="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em className="icon ni ni-info"></em></a>
                  </div>
                  <div className="nk-block nk-block-middle nk-auth-body">
                    <div className="brand-logo pb-5">
                      <a href="javascript:void(0)" className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="logo-dark" />
                      </a>
                    </div>
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title text-black-color">Log-In</h5>
                        <div className="nk-block-des text-black-color">
                          <p>Access the Zoom Solar panel using your email and password.</p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <form onSubmit={handleSubmit(onSubmit)} className="form-validate is-alter" autoComplete="off">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label text-black-color" htmlFor="email-address">Email or Username</label>
                        </div>
                        <div className="form-control-wrap">
                          <input autoComplete="off" type="text" className="form-control form-control-lg" required id="email-address" placeholder="Enter your email address or username" {...register("email", { required: true })} />
                        </div>
                      </div>
                      {/* <!-- .form-group --> */}
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label text-black-color" htmlFor="password">Password</label>
                        </div>
                        <div className="form-control-wrap">
                          <a tabIndex="-1" href="javascript:void(0)" onClick={showPasswordHandler} className={`${showPassword === true ? "form-icon form-icon-right passcode-switch lg is-shown" : "form-icon form-icon-right passcode-switch lg"}`} data-target="password">
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                            <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                          </a>
                          <input autoComplete="new-password" type={`${showPassword === true ? "text" : "password"}`} className={`${showPassword === true ? "form-control form-control-lg valid is-shown" : "form-control form-control-lg"}`} required id="password" placeholder="Enter your password" {...register("password", { required: true })} />
                        </div>
                      </div>
                      {/* <!-- .form-group --> */}
                      <div className="form-group">
                        {
                          submitLoading ? <button disabled className="btn btn-lg btn-block text-dark">Loading...</button> : <button type='submit' className="btn btn-lg bg-color-login-button btn-block">Sign in</button>
                        }
                      </div>
                    </form>
                    {/* <!-- form --> */}
                    {/* <div className="form-note-s2 pt-4"> New on our platform? <a href="javascript:void(0)">Create an account</a>
                    </div> */}
                  </div>
                  {/* <!-- .nk-block --> */}
                </div>
                {/* <!-- .nk-split-content --> */}
                <div className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right" data-toggle-body="true" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
                  <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                    <div className="slider-init">
                      <div className="slider-item">
                        <div className="nk-feature nk-feature-center">
                          <div className="nk-feature-img">
                          <img class="round" src="./images/zoomrobot.png" srcset="./images/zoomrobot2x.png 2x" alt="" />
                          </div>
                          <div className="nk-feature-content py-4 p-sm-5">
                            <h4>Zoom Solar</h4>
                            <p>The widest range of solar panel cleaning products. The automation of automatic access of plant.</p>
                          </div>
                        </div>
                      </div>
                      {/* <!-- .slider-item --> */}
                    </div>
                    {/* <!-- .slider-init --> */}
                  </div>
                  {/* <!-- .slider-wrap --> */}
                </div>
                {/* <!-- .nk-split-content --> */}
              </div>
              {/* <!-- .nk-split --> */}
            </div>
            {/* <!-- wrap @e --> */}
          </div>
          {/* <!-- content @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
      {/* <!-- app-root @e --> */}
    </>
  )
}

export default Login