import React from 'react'
import { useLocation } from 'react-router-dom'

const ZoomBotDetails = () => {
  const location = useLocation()
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> {location.state.zoom.zoombot_name} Details </h3>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="more-options"><em
                className="icon ni ni-more-v"></em></a>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="row g-gs">
          <div className="col-md-4 col-lg-4 col-xxl-3">
            <div className="card">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">Battery Indication</h6>
                  </div>
                </div>
                <div className="pt-4">
                  <div className="battery-container">
                    <div className={location.state.zoom.battery_style} style={{ width : location.state.zoom.batter_range}}></div>
                    {/* <div className="battery-cap"></div>
                    <div className="battery-indicator"></div>  */}
                  </div>
                  <div className="pt-3 g-2">
                    <div className="traffic-channel-data">
                      <div className="title"><span className="dot dot-lg sq" data-bg="#00ff00" style={{background : location.state.zoom.battery_style === "battery-success" ? "#4CAF50" : location.state.zoom.battery_style === "battery-warning" ? "#efaf13" : "#e81309" }}></span><span>Battery
                        Indication</span></div>
                      <div className="amount">{location.state.zoom.batter_range} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-8 col-sm-12 d-flex">
            <div className="card">
              <div className="card-inner mb-n2">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">Current Bot Position</h6>
                  </div>
                </div>
              </div>
              <div className="card-inner pt-0">
                <div className="">
                  <img src="./images/Robot at Position B.png" alt="current position" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7">
            <div className="card">
              <div className="card-inner mb-n2">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">{location.state.zoom.zoombot_name} Logs</h6>
                  </div>
                </div>
              </div>
              <div className="nk-tb-list is-loose">
                <div className="nk-tb-item nk-tb-head">
                  <div className="nk-tb-col"><span>Log Name</span></div>
                  <div className="nk-tb-col"><span>Configurable Date</span></div>
                  <div className="nk-tb-col"><span>Configurable Time</span></div>
                  <div className="nk-tb-col"><span>On/Off</span></div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <div className="icon-text">
                      <span className="tb-lead">log_20231103_0138</span>
                    </div>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>23-10-2023</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>11:00 AM</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">ON</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <div className="icon-text">
                      <span className="tb-lead">log_20231103_0138</span>
                    </div>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>23-10-2023</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>11:00 AM</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">ON</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <div className="icon-text">
                      <span className="tb-lead">log_20231103_0138</span>
                    </div>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>23-10-2023</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>11:00 AM</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">ON</span>
                  </div>
                </div>
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <div className="icon-text">
                      <span className="tb-lead">log_20231103_0138</span>
                    </div>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>23-10-2023</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub tb-amount"><span>11:10 AM</span></span>
                  </div>
                  <div className="nk-tb-col">
                    <span className="tb-sub">ON</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6 col-xxl-4">
            <div className="card">
              <div className="card-inner border-bottom">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">Current Position</h6>
                  </div>
                </div>
              </div>
              <ul className="nk-support">
                <li className="nk-support-item">
                   {/* <div className="user-avatar">
                    <img src="./images/avatar/a-sm.jpg" alt="">
                  </div>  */}
                  <div className="nk-support-content">
                    <div className="title">
                      <span>Position A</span><span className="badge badge-dot badge-dot-xs bg-warning ms-1">Home</span>
                    </div>
                    <p>28-10-2023 11:05 am</p>
                     {/* <span className="time">-</span>  */}
                  </div>
                </li>
                <li className="nk-support-item">
                   {/* <div className="user-avatar bg-purple-dim">
                    <span>DM</span>
                  </div>  */}
                  <div className="nk-support-content">
                    <div className="title">
                      <span>Position B</span><span className="badge badge-dot badge-dot-xs bg-success ms-1">Active</span>
                    </div>
                    <p>28-10-2023 11:11 am</p>
                     {/* <span className="time">2 hours ago</span>  */}
                  </div>
                </li>
                <li className="nk-support-item">
                  {/* <div className="user-avatar">
                    <img src="./images/avatar/b-sm.jpg" alt="">
                  </div>  */}
                  <div className="nk-support-content">
                    <div className="title">
                      <span>Position B</span><span className="badge badge-dot badge-dot-xs bg-info ms-1">Late</span>
                    </div>
                    <p>28-10-2023 11:11 am</p>
                    {/* <span className="time">3 Hours ago</span>  */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZoomBotDetails