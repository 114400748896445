import React from 'react'
import { zoom_list } from './DATA'
import { NavLink } from 'react-router-dom'

const ZoomBotList = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Zoom Bot Lists</h3>
            <div className="nk-block-des text-soft">
              <p>You have total 4 Zoom Bot Installed.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="more-options"><em
                className="icon ni ni-more-v"></em></a>
              <div className="toggle-expand-content" data-content="more-options">
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <em className="icon ni ni-search"></em>
                      </div>
                      <input type="text" className="form-control" id="default-04" placeholder="Search by name" />
                    </div>
                  </li>
                  <li>
                    <div className="drodown">
                      <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        data-bs-toggle="dropdown">Status</a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <ul className="link-list-opt no-bdr">
                          <li><a href="#"><span>Actived</span></a></li>
                          <li><a href="#"><span>Inactived</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="nk-block-tools-opt">
                    <a href="javascript:void(0)" className="btn btn-icon btn-primary d-md-none"><em
                      className="icon ni ni-plus"></em></a>
                    <a href="javascript:void(0)" className="btn btn-primary d-none d-md-inline-flex"><em
                      className="icon ni ni-plus"></em><span>Add</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <table className="nk-tb-list is-separate nk-tb-ulist">
          <thead>
            <tr className="nk-tb-item nk-tb-head">
              <th className="nk-tb-col"><span className="sub-text">Zoombot Name</span></th>
              <th className="nk-tb-col"><span className="sub-text">Status</span></th>
              <th className="nk-tb-col"><span className="sub-text">Deployed Date-Time</span></th>
              <th className="nk-tb-col"><span className="sub-text">Battery</span></th>
            </tr>
          </thead>
          <tbody>
            {
              zoom_list.map((api) => {
                return <tr className="nk-tb-item">
                <td className="nk-tb-col">
                  <NavLink to="/zoom-details" state={{ zoom : api}} className="project-title">
                    <div className="project-info">
                      <h6 className="title"> {api.zoombot_name} </h6>
                    </div>
                  </NavLink>
                </td>
                <td className="nk-tb-col">
                  <span className={`${api.status === "Active" ? "badge badge-dim rounded-pill bg-success" : "badge badge-dim rounded-pill bg-danger"}`} >{api.status}</span>
                </td>
                <td className="nk-tb-col">
                  <span>{api.deployed_datetime}</span>
                </td>
                <td className="nk-tb-col">
                  <div className="battery">
                    <div className={`${api.battery_status && api.battery_status} battery-level`} 
                    style={{ height: api.batter_range }}></div>
                  </div>
                </td>
              </tr>
              })
            } 
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ZoomBotList