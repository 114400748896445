export const panel_list = [
  {
    place: "Baner",
    city: "Pune",
    panel: "Panel - 1",
    zoom_bot: "Zoom Bot - 1",
    status: "Active"
  },
  {
    place: "Kothrude",
    city: "Pune",
    panel: "Panel - 4",
    zoom_bot: "Zoom Bot - 3",
    status: "Active"
  },
  {
    place: "Andheri",
    city: "Mumbai",
    panel: "Panel - 3",
    zoom_bot: "Zoom Bot - 4",
    status: "Inactive"
  },
  {
    place: "Dharampeth",
    city: "Nagpur",
    panel: "Panel - 6",
    zoom_bot: null,
    status: "Not Assigned"
  },
  {
    place: "Goregoan",
    city: "Mumbai",
    panel: "Panel - 10",
    zoom_bot: "Zoom Bot - 7",
    status: "Active"
  },
]

export const zoom_list = [
  {
    zoombot_name: "Zoombot - 1",
    status: "Active",
    deployed_datetime: "22th Oct 2023 - 11:00 AM",
    battery_status: null,
    batter_range : "75%",
    battery_style : "battery-success"
  },
  {
    zoombot_name: "Zoombot - 4",
    status: "Active",
    deployed_datetime: "23th Oct 2023 - 03:24 PM",
    battery_status: "alerts",
    batter_range : "10%",
    battery_style : "battery-alerts"
  },
  {
    zoombot_name: "Zoombot - 7",
    status: "Active",
    deployed_datetime: "29th Oct 2023 - 02:18 PM",
    battery_status: null,
    batter_range : "50%",
    battery_style : "battery-success"
  },
  {
    zoombot_name: "Zoombot - 2",
    status: "Active",
    deployed_datetime: "26th Oct 2023 - 10:08 AM",
    battery_status: null,
    batter_range : "100%",
    battery_style : "battery-success"
  },
  {
    zoombot_name: "Zoombot - 3",
    status: "Active",
    deployed_datetime: "27th Oct 2023 - 11:47 AM",
    battery_status: "warns",
    batter_range : "23%",
    battery_style : "battery-warning"
  },
]