import React from 'react'
import { panel_list } from "./DATA"

const PanelList = () => {
  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Panel Lists</h3>
            <div className="nk-block-des text-soft">
              <p>You have total 5 Panel.</p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a href="#" className="btn btn-icon btn-trigger toggle-expand me-n1" data-target="more-options"><em
                className="icon ni ni-more-v"></em></a>
              <div className="toggle-expand-content" data-content="more-options">
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <em className="icon ni ni-search"></em>
                      </div>
                      <input type="text" className="form-control" id="default-04" placeholder="Search by name" />
                    </div>
                  </li>
                  <li>
                    <div className="drodown">
                      <a href="#" className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        data-bs-toggle="dropdown">Status</a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <ul className="link-list-opt no-bdr">
                          <li><a href="#"><span>Actived</span></a></li>
                          <li><a href="#"><span>Inactived</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="nk-block-tools-opt">
                    <a href="javascript:void(0)" className="btn btn-icon btn-primary d-md-none"><em
                      className="icon ni ni-plus"></em></a>
                    <a href="javascript:void(0)" className="btn btn-primary d-none d-md-inline-flex"><em
                      className="icon ni ni-plus"></em><span>Add</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="nk-tb-list is-separate mb-3">
          <div className="nk-tb-item nk-tb-head">
            <div className="nk-tb-col"><span className="sub-text">Place</span></div>
            <div className="nk-tb-col tb-col-mb"><span className="sub-text">City</span></div>
            <div className="nk-tb-col tb-col-md"><span className="sub-text">Panel</span></div>
            <div className="nk-tb-col tb-col-lg"><span className="sub-text">Zoom Bot</span></div>
            <div className="nk-tb-col tb-col-md"><span className="sub-text">Status</span></div>
          </div>
          {
            panel_list.map((panel) => {
              return <div className="nk-tb-item">
              <div className="nk-tb-col">
                <a href="javascript:void(0)">
                  <div className="user-info">
                    <span className="tb-lead">{panel.place} <span className="dot dot-success d-md-none ms-1"></span></span>
                  </div>
                </a>
              </div>
              <div className="nk-tb-col tb-col-mb">
                <span className="tb-amount"> {panel.city} </span>
              </div>
              <div className="nk-tb-col tb-col-md">
                <span> {panel.panel} </span>
              </div>
              <div className="nk-tb-col tb-col-lg">
                <span> {panel.zoom_bot ? panel.zoom_bot : "-"} </span>
              </div>
              <div className="nk-tb-col tb-col-md">
              {panel.status === "Active" ? <span className="badge badge-dim rounded-pill bg-success">{panel.status}</span> : <span className="badge badge-dim rounded-pill bg-danger">{panel.status}</span>}
              </div>
            </div>
            })
          }
        </div>
      </div>
    </>
  )
}

export default PanelList