import React, { useState, useEffect } from 'react';
import Login from './Component/Authenticate/Login'
import Base from './Component/Base/Base'
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const App = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  console.log(isLoggedIn)

  // Set values in localstorage, userData and isLoggedIn after successful login
  const loginHandler = (data) => {
    console.log(data)
    secureLocalStorage.setItem('isLoggedIn', 'USER_LOGGED_IN');
    secureLocalStorage.setItem('userID', data.id);
    secureLocalStorage.setItem('loggedId', data.user.id);
    secureLocalStorage.setItem('username', data.user.username);
    secureLocalStorage.setItem('email', data.user.email);
    secureLocalStorage.setItem('superior_id', data.superior_id);
    secureLocalStorage.setItem('role_name', data.role[0].name);
    const privileges_list = data.role[0].privileges
    const privileges = privileges_list.map((pr) => { return pr.name })
    console.log(privileges)
    console.log(privileges_list)
    secureLocalStorage.setItem('privileges', privileges)
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    navigate("/")
    secureLocalStorage.setItem('isLoggedIn', 'USER_LOGGED_OUT')
    secureLocalStorage.clear();
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem('isLoggedIn');
    if (userStatus === 'USER_LOGGED_IN') {
      setIsLoggedIn(true);
    } else if (userStatus === 'USER_LOGGED_OUT') {
      setIsLoggedIn(false);
    }
  }, [])

  return (
    <>
      {isLoggedIn && <Base logoutHandler={logoutHandler} />}
      {!isLoggedIn && <Login loginHandler={loginHandler} />}
    </>
  )
}

export default App
