import React from 'react'
import { NavLink } from 'react-router-dom';

const Sidebar = (props) => {
  return (
    <>
      <div className={`nk-sidebar nk-sidebar-fixed is-light ${props.showSidebar ? "nk-sidebar-active" : ""}`} data-content="sidebarMenu">
        <div className="nk-sidebar-element nk-sidebar-head sidebar-background">
          <div className="nk-sidebar-brand">
            <a href="javascript:void(0)" className="logo-link nk-sidebar-logo">
              <img className="logo-light logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
              <img className="logo-dark logo_img logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
              <img className="logo-small logo-images logo-img-small" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="zoom solar logo" />
            </a>
          </div>
          <div className="nk-menu-trigger me-n2">
            <a href="javascript:void(0)" onClick={props.hideSidebar} className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
          </div>
        </div>
        {/* <!-- .nk-sidebar-element --> */}
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {/* <!-- .nk-menu-item --> */}
                <li className="nk-menu-item">
                  <NavLink to="/" className="nk-menu-link">
                    <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                    <span className="nk-menu-text">Panel List</span>
                  </NavLink>
                </li>

                <li className="nk-menu-item">
                  <NavLink to="/zoom-list" className="nk-menu-link">
                    <span className="nk-menu-icon"><em class="icon ni ni-yelp"></em></span>
                    <span className="nk-menu-text">Zone List</span>
                  </NavLink>
                </li>
                {/* <!-- .nk-menu-item --> */}
              </ul>
              {/* <!-- .nk-menu --> */}
            </div>
            {/* <!-- .nk-sidebar-menu --> */}
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-element --> */}
      </div>
    </>
  )
}

export default Sidebar