import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Content from './Content'

const Base = (props) => {
  // "showSidebar" state handles the sidebar in mobile responsive to toggle the hide and show case
  const [showSidebar ,setShowSidebar] = useState(false)
  const showSidebarMobile = () => {
    setShowSidebar(true)
  }
  const hideSidebar = () => {
    setShowSidebar(false)
  }
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- sidebar @s --> */}
          <Sidebar
            showSidebar={showSidebar}
            hideSidebar={hideSidebar} />
          {/* <!-- sidebar @e --> */}
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap ">
            {/* <!-- main header @s --> */}
            <Header showSidebarMobile={showSidebarMobile} logoutHandler={props.logoutHandler} />
            {/* <!-- main header @e --> */}
            {/* <!-- content @s --> */}
            <Content />
            {/* <!-- content @e --> */}
          </div>
          {/* <!-- wrap @e --> */}
        </div>
        {/* <!-- main @e --> */}
      </div>
    </>
  )
}

export default Base