import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PanelList from '../Pages/PanelList'
import ZoomBotDetails from '../Pages/ZoomBotDetails'
import ZoomBotList from '../Pages/ZoomBotList'

const Content = () => {
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <Routes>
                <Route path="/" element={<PanelList />} />
                <Route path="/zoom-details" element={<ZoomBotDetails />} />
                <Route path="/zoom-list" element={<ZoomBotList />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content